<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>
        <ion-title>{{ $route.name }}</ion-title>
        <ion-button @click="goToHome()" color="primary" fill="clear" slot="end">
          <ion-icon icon="home" />
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-label>Ambito generale</ion-label>
        <ion-toggle
          color="secondary"
          slot="start"
          :checked="fcmGenerale"
          @ionChange="saveNotifiche(fcmTopics.GENERALE, fcmGenerale)"
        ></ion-toggle>
      </ion-item>
      <!--ion-item-divider color="secondary" /-->
      <ion-item-divider color="secondary">
        <ion-label> AREE TEMATICHE </ion-label>
      </ion-item-divider>
      <ion-item v-for="not in fcmAll" :key="not.topic" cols="12" lines="none">
        <ion-label>{{ not.name }}</ion-label>
        <ion-toggle
          color="secondary"
          slot="start"
          :checked="not.status"
          @ionChange="saveNotifiche(not.topic, not.status)"
        ></ion-toggle>
      </ion-item>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  onUpdated,
  ref,
  toRefs,
  onBeforeUpdate,
  onBeforeMount,
  watch,
} from "vue";
import JnDoc from "../../components/DocCard.vue";
import { document, search } from "ionicons/icons";
import { addIcons } from "ionicons";
import { useRouter, useRoute } from "vue-router";
import { useCookie } from "@vue-composable/cookie";
import { JnService } from "@/services/jn.service";
import {
  FcmTopicsAreeTematiche,
  FcmTopicsPlatform,
} from "@/models/enums.model";
import firebaseConfig from "../../firebaseConfig.js";
import firebase from "firebase/app";
import { JnFirebase } from "@/firebase";
addIcons({
  document: document,
  search: search,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonLoading,
  IonToggle,
  IonFab,
  IonFabButton,
  IonIcon,
  modalController,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  IonItemDivider,
  alertController,
} from "@ionic/vue";

export default defineComponent({
  name: "JnNotifiche",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    // IonButtons,
    //IonMenuToggle,
    // IonTitle,
    // IonMenuButton,
    //  IonGrid,
    //IonRow,
    // IonCol,
    //  IonText,
    IonLabel,
    // IonItem,
    IonIcon,
    IonButtons,
    IonButton,
    IonMenuButton,
    IonTitle,
    IonToggle,
    IonItem,
    IonItemDivider,
  },
  setup(props) {
    const router = useRouter();

    const fcmTopics = FcmTopicsAreeTematiche;

    const fcmGeneraleCookie = useCookie("fcm-topic-generale", "false", {
      expires: new Date(9999, 12, 30),
    });

    const fcmRegistrationTokenCookie = useCookie("fcm-token", "", {
      expires: new Date(9999, 12, 30),
    });

    const fcmGenerale = ref(false);

    const fcmAll = ref([
      {
        name: "Diritto Societario",
        topic: FcmTopicsAreeTematiche.DIRITTO_SOCIETARIO,
        status: false,
      },
      {
        name: "Diritto Fallimentare",
        topic: FcmTopicsAreeTematiche.DIRITTO_FALLIMENTARE,
        status: false,
      },
      {
        name: "Diritto Tributario",
        topic: FcmTopicsAreeTematiche.DIRITTO_TRIBUTARIO,
        status: false,
      },
      {
        name: "Diritto Penale",
        topic: FcmTopicsAreeTematiche.DIRITTO_PENALE,
        status: false,
      },
      {
        name: "Diritto del Lavoro",
        topic: FcmTopicsAreeTematiche.DIRITTO_DEL_LAVORO,
        status: false,
      },
      {
        name: "Assicurazioni e Risarcimento Danni",
        topic: FcmTopicsAreeTematiche.ASSICURAZIONI,
        status: false,
      },
      {
        name: "Diritto di Famiglia e Successioni",
        topic: FcmTopicsAreeTematiche.DIRITTO_DI_FAMIGLIA,
        status: false,
      },
      {
        name: "Protezione dei dati personali",
        topic: FcmTopicsAreeTematiche.PRIVACY,
        status: false,
      },
      {
        name: "Mercati immobiliari",
        topic: FcmTopicsAreeTematiche.MERCATI_IMMOBILIARI,
        status: false,
      },
      {
        name: "Contrattualistica di diritto privato",
        topic: FcmTopicsAreeTematiche.CONTRATTUALISTICA_DIRITTO_PRIVATO,
        status: false,
      },
      {
        name: "Bancario e mercati finanziari",
        topic: FcmTopicsAreeTematiche.BANCARIO_MERCATI_FINANZIARI,
        status: false,
      },
      {
        name: "Diritto Processuale Telematico",
        topic: FcmTopicsAreeTematiche.PROCESSO_TELEMATICO,
        status: false,
      },
    ]);

    let messaging!: firebase.messaging.Messaging;

    const changing = ref(false);
    const token = ref("");

    function goToHome() {
      router.push("/main/home");
    }

    const showAlert = async (message: string) => {
      const alert = await alertController.create({
        header: "Errore",
        message: message,
        buttons: [
          {
            text: "Ok",
            role: "ok",
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
    };

    const saveNotifiche = async (fcmTopic: string, fcmValue: boolean) => {
      if (changing.value) {
        changing.value = false;
        console.log("JnNotifiche.saveNotifiche: setting to false");
        return;
      }
      console.log(
        "JnNotifiche.saveNotifiche(vapid): " + process.env.VUE_APP_FCM_VAPID_KEY
      );
      try {
        token.value = await messaging!.getToken({
          vapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
        });
        fcmRegistrationTokenCookie.setCookie(token.value, {
          expires: new Date("9999-12-31T23:59:59.999Z"),
        });
        const fcmCookie = useCookie("fcm-topic-" + fcmTopic, String(fcmValue), {
          expires: new Date(9999, 12, 30),
        });
        const active = fcmCookie.cookie.value == "true";
        console.log("JnNotifiche.saveNotifiche(active): " + active);
        if (active) {
          try {
            await JnService.fcmUnregisterTopic(token.value!, fcmTopic);
            console.log(
              "JnNotifiche.saveNotifiche: unregistered client with token '" +
                token.value +
                "' from topic '" +
                fcmTopic +
                "'"
            );

            console.log(
              "JnNotifiche.saveNotifiche: FCM deleted token " +
                token.value +
                "'"
            );
            fcmCookie.setCookie("false", {
              expires: new Date("9999-12-31T23:59:59.999Z"),
            });
          } catch (err) {
            await showAlert(
              "Impossibile modificare l'impostazione. Riprovare più tardi."
            );
          }
        } else {
          try {
            console.log(
              "JnNotifiche.saveNotifiche: registered client with token '" +
                token.value +
                "'"
            );
            await JnService.fcmRegisterTopic(
              token.value,
              FcmTopicsPlatform.APP
            );
            await JnService.fcmRegisterTopic(token.value, fcmTopic);
            console.log(
              "JnNotifiche.saveNotifiche: registered client with token '" +
                token.value +
                "' to topic '" +
                fcmTopic +
                "'"
            );
            fcmCookie.setCookie("true", {
              expires: new Date("9999-12-31T23:59:59.999Z"),
            });
          } catch (err) {
            showAlert(
              "Impossibile modificare l'impostazione. Riprovare più tardi."
            );
          }
        }
      } catch (err) {
        console.error("JnNotifiche.saveNotifiche(err): " + err);
      }
    };

    onMounted(async () => {
      let fcmCookie = useCookie("fcm-topic-" + FcmTopicsAreeTematiche.GENERALE);
      if (fcmCookie.cookie && fcmCookie.cookie.value == "true") {
        fcmGenerale.value = true;
      } else {
        fcmGenerale.value = false;
        fcmCookie.setCookie("false", {
          expires: new Date(9999, 12, 30),
        });
      }
      for (const fcmTopic of fcmAll.value) {
        fcmCookie = useCookie("fcm-topic-" + fcmTopic.topic);
        console.log(
          "JnNotifiche.onMounted(" +
            fcmTopic.name +
            "):" +
            JSON.stringify(fcmCookie.cookie.value)
        );
        if (fcmCookie.cookie && fcmCookie.cookie.value == "true") {
          console.log(
            "JnNotifiche.onMounted(" + fcmTopic.name + "): setting to true"
          );
          fcmTopic.status = true;
        } else {
          fcmCookie.setCookie("false", {
            expires: new Date("9999-12-31T23:59:59.999Z"),
          });
          console.log(
            "JnNotifiche.onMounted(" + fcmTopic.name + "): setting to false"
          );
          fcmTopic.status = false;
        }
      }
      try {
        console.log(
          "JnNotifiche.onMounted(vapid): " + process.env.VUE_APP_FCM_VAPID_KEY
        );
        messaging = JnFirebase.init();
        token.value = await messaging!.getToken({
          vapidKey: process.env.VUE_APP_FCM_VAPID_KEY,
        });
        fcmRegistrationTokenCookie.setCookie(token.value, {
          expires: new Date("9999-12-31T23:59:59.999Z"),
        });
      } catch (err) {
        console.error("JnNotifiche.onMounted: " + err);
      }
      /* await showToken('Token: ' + token.value);
      await showToken(
        'Cookie Notifiche Generali: ' + fcmNotificheGenerali.value
      );*/
    });

    return {
      /*  fcmDirittoSocietario,
      fcmDirittoFallimentare,
      fcmDirittoTributario,
      fcmDirittoDelLavoro,
      fcmAssicurazioni,
      fcmDirittoDiFamiglia,
      fcmPrivacy,
      fcmMercatiImmobiliari,
      fcmContrattualisticaDirittoPrivato,
      fcmBancarioMercatiFinanziari,
      fcmProcessoTelematico,*/
      saveNotifiche,
      goToHome,
      fcmAll,
      fcmTopics,
      fcmGenerale,
      fcmGeneraleCookie,
    };
  },
});
</script>
